<template>
    <div class="flex justify-end mt-10">
        <a
            :v-if="slug"
            target="_blank"
            class="btn btn-outline-secondary w-30 mr-2"
            :class="{ 'cursor-wait pointer-events-none opacity-50': loading }"
            :href="`https://kruizy-rossii.com/raspisanie-rechnyh-kruizov/${slug}/`"
        >
            Перейти на страницу
        </a>
    </div>

    <form method="post" class="grid grid-cols-12 gap-6 mt-4" :class="{ 'disabled-form': loading }">
        <div v-if="loading" class="absolute z-100 left-0 top-0 w-full h-full flex items-center justify-center">
            <Preloader />
        </div>

        <div class="relative col-span-6">
            <label for="form-name" class="form-label">
                Название
                <sup v-if="v$.form.title.required" class="text-theme-6">*</sup>
            </label>
            <label v-if="v$.form.title.$errors.length" for="form-name" class="form-input-text-error">
                {{ getOverrideMessage(v$.form.title.$errors[0].$message) }}
            </label>
            <input
                id="form-name"
                v-model="form.title"
                type="text"
                class="form-control w-full"
                :class="{ 'form-input-error': v$.form.title.$errors.length }"
                placeholder=""
                disabled
                autocomplete="off"
            />
        </div>

        <div class="relative col-span-6">
            <label for="form-slug" class="form-label">
                Ссылка
                <sup v-if="v$.form.slug.required" class="text-theme-6">*</sup>
            </label>
            <label v-if="v$.form.slug.$errors.length" for="form-slug" class="form-input-text-error">
                {{ getOverrideMessage(v$.form.slug.$errors[0].$message) }}
            </label>
            <input
                id="form-slug"
                v-model="form.slug"
                type="text"
                class="form-control w-full"
                :class="{ 'form-input-error': v$.form.slug.$errors.length }"
                placeholder=""
                disabled
                autocomplete="off"
            />
        </div>

        <!--        <div class="col-span-6">-->
        <!--            <div>-->
        <!--                <label class="form-label">Изображение <sup>*</sup></label>-->
        <!--                <UploadImage-->
        <!--                    v-model="form.photo"-->
        <!--                    v-model:delete="form.image_delete"-->
        <!--                    :image="imagePreview"-->
        <!--                ></UploadImage>-->
        <!--            </div>-->
        <!--        </div>-->

        <div class="col-span-4">
            <label for="form-offers" class="form-label">Спецпредложения</label>

            <Multiselect
                id="form-offers"
                v-model="form.offers"
                mode="multiple"
                :hide-selected="false"
                :close-on-select="false"
                :options="offersOptions"
            />
        </div>

        <div class="col-span-4">
            <label for="form-offers" class="form-label">Акции</label>

            <Multiselect
                id="form-discount_id"
                v-model="form.discount_id"
                track-by="name"
                label="name"
                value-prop="id"
                placeholder="Выберите акцию"
                :hide-selected="false"
                :close-on-select="false"
                :searchable="true"
                :options="discountOptions"
            />
        </div>

        <div class="col-span-4">
            <label for="form-sale_visible" class="form-label">Текст акции на картинке</label>
            <select id="form-sale_visible" v-model="form.sale_visible" class="form-select">
                <option :value="true">Виден</option>
                <option :value="false">Скрыт</option>
            </select>
        </div>

        <div class="relative col-span-4" title="SEO тэги для круиза">
            <label for="form-hidden_tag" class="form-label">Скрытые тэги</label>

            <Multiselect
                id="form-hidden_tag"
                v-model="form.hidden_tag"
                :options="form.hidden_tag"
                mode="tags"
                placeholder="SEO тэги круиза"
                :hide-selected="false"
                :close-on-select="false"
                :native="false"
                :searchable="true"
                :create-option="true"
                no-results-text="Введите новый тэг и Enter чтобы создать"
                no-options-text="Введите новый тэг и Enter чтобы создать"
            />
        </div>

        <!--        <div class="col-span-12">-->
        <!--            <label class="form-label">Описание</label>-->
        <!--            <CustomWyswig v-model="form.description" />-->
        <!--        </div>-->

        <div class="col-span-12 flex items-center justify-end mt-5">
            <router-link to="#" class="btn btn-secondary w-24 mb-2 mr-2" @click.prevent="redirectToMainPage"
                >Отмена</router-link
            >
            <button type="submit" class="btn btn-primary mb-2 mr-2" @click.prevent="save(false)">
                Сохранить и выйти
            </button>
            <button type="submit" class="btn btn-primary mb-2" @click.prevent="save(true)">Сохранить</button>
        </div>

        <div class="col-span-12">
            <h1 style="font-size: 1.5em" class="mb-5">Расписание</h1>
            <router-view />
        </div>
    </form>
</template>

<script>
import dayjs from 'dayjs';
import { useVuelidate } from '@vuelidate/core';
import { required } from '@vuelidate/validators';
import { errorResponse } from '@/mixins/form/form-mixin';
import Multiselect from '@vueform/multiselect';
import CustomWyswig from '@/components/base/custom-wyswig/Main';
import Preloader from '@/components/preloader/Main';
import UploadImage from '@/components/upload-image/UploadImage';
import CruiseTimetables from '@/views/cruises/timetable/Main.vue';
import { formPreparation } from '@/utils/object-to-form-data';
import { helper as $h } from '@/utils/helper';
import { pageMixin } from '@/mixins/pageMixin';

export default {
    name: 'Edit',
    components: { CruiseTimetables, Preloader, UploadImage, CustomWyswig, Multiselect },
    mixins: [errorResponse, formPreparation, pageMixin],
    setup() {
        return { v$: useVuelidate() };
    },
    data() {
        return {
            loading: false,
            form: {
                title: null,
                // photo: null,
                slug: null,
                // description: null,
                offers: [],
                discount_id: null,
                hidden_tag: [],
            },
            offersOptions: [],
            discountOptions: [],
            seo: [],
            departure_date_month: null,
            slug: null,
            seoHeaders: [
                { field: 'code', label: 'Название параметра' },
                { field: 'value', label: 'Значение' },
            ],
        };
    },
    created() {
        this.$store.commit('main/setPageHeader', 'Круизы / Редактирование');
        this.fetchData();
    },
    validations() {
        return {
            form: {
                title: { required },
                slug: { required },
            },
        };
    },
    methods: {
        async fetchData() {
            this.loading = true;
            this.axios
                .get(`/cruises/${this.$route.params.cruise_id}/edit`)
                .then(({ data }) => {
                    const { cruise, offers, discounts } = data.data;

                    this.form.title = cruise.title;
                    // this.form.photo = cruise.photo;
                    this.form.slug = cruise.slug;
                    this.form.offers = cruise.offers;
                    // this.form.description = cruise.description;
                    this.form.discount_id = cruise.discount_id;
                    this.form.hidden_tag = cruise.hidden_tag;

                    this.offersOptions = offers.map((offer) => offer.title);
                    this.discountOptions = [...discounts];
                    this.departure_date_month = $h.getRussianInMonth(dayjs(cruise.departure_date_time).format('MM'));

                    this.slug = cruise.slug;

                    this.loading = false;
                })
                .catch((ex) => {
                    console.log('cant fetch single data: ' + ex);
                    this.loading = false;
                    // this.$router.push('/error-page');
                });
        },
        async save(flag) {
            const isFormCorrect = await this.v$.$validate();
            if (!isFormCorrect) {
                this.$notify({ text: 'Заполните все поля правильно', type: 'error' });
                return;
            }

            this.loading = true;
            this.form.seo = this.seo;
            const formData = formPreparation(this.form);

            this.axios
                .post(`/cruises/${this.$route.params.cruise_id}`, formData, { params: { _method: 'patch' } })
                .then((res) => {
                    if (flag) {
                        this.fetchData();
                    } else {
                        // this.$router.push({ name: 'cruises' });
                        this.redirectToMainPage();
                    }

                    this.$notify('Данные сохранены');
                })
                .catch((ex) => {
                    this.errorResponse(ex);
                });
        },
        getOverrideMessage(message) {
            return $h.getOverrideMessage(message);
        },
        redirectToMainPage() {
            this._pMTransitionToEditPageWithQuery('cruises');
        },
    },
};
</script>
